import { graphql } from "gatsby"
import React, { useEffect } from "react"
import { LocalizedLink } from "../../components/localized_link"
import MenuSection from "../../components/menu_section"
import { scrollWindowTo } from "../../utils"
import isMobile from "../../utils/is_mobile"

const { default: Layout } = require("../../components/layout")
const { default: SEO } = require("../../components/seo")

const WineSectionPage = props => {
  const { path, data, location } = props

  const digitalQrMode = location?.search === "?digitalQr" && isMobile()

  const menu = data?.prismicWinesection?.data

  useEffect(() => {
    if (Boolean(location.hash)) {
      scrollWindowTo(location.hash, isMobile() ? 96 : 1)
    }
  }, [location.hash])

  return (
    <Layout path={path} digitalQrMode={digitalQrMode} location={location}>
      <SEO title={menu?.title.text} />
      <div className="section not-that-much-margin-on-top">
        {/* {menu?.image?.localFile?.childImageSharp?.fluid && (
          <div className="image-section">
            <div className="image-container">
              <Img fluid={menu?.image.localFile.childImageSharp.fluid} />
            </div>
          </div>
        )} */}
        <div className="container">
          <h1 className="custom dark">{menu?.title?.text}</h1>
        </div>
      </div>
      {(menu?.sections ?? []).map(
        (section, index) =>
          section?.section?.document?.[0]?.data && (
            <MenuSection
              key={index}
              menu={section?.section?.document?.[0]?.data}
              inverted={index % 2 === 1}
              wineSection={true}
            />
          )
      )}
      <LocalizedLink to="/wines" style={{ textDecoration: "none" }}>
        <div className="custom_menu_cta">
          <p>
            {props?.pathContext?.locale === "it"
              ? "Vedi tutti i nostri vini"
              : "See all our wines"}
          </p>
          <svg
            width="85"
            height="16"
            viewBox="0 0 85 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M74.4574 0L72.5992 1.41L79.9527 7H0V9H79.9527L72.5992 14.59L74.4574 16L85 8L74.4574 0Z"
              fill="#e46f4d"
            />
          </svg>
        </div>
      </LocalizedLink>
    </Layout>
  )
}

export default WineSectionPage

export const query = graphql`
  query($slug: String!, $localeRegex: String) {
    prismicWinesection(
      data: { slug: { eq: $slug } }
      lang: { regex: $localeRegex }
    ) {
      data {
        title {
          text
        }
        # image {
        #   localFile {
        #     childImageSharp {
        #       fluid(maxWidth: 1000, quality: 95, pngCompressionSpeed: 1) {
        #         ...GatsbyImageSharpFluid_withWebp
        #       }
        #     }
        #   }
        # }
        sections {
          section {
            document {
              data {
                id
                title
                dishes {
                  dish_name
                  dish_description
                  dish_price
                }
                images {
                  section_image {
                    localFile {
                      childImageSharp {
                        fluid(
                          maxWidth: 1000
                          quality: 95
                          pngCompressionSpeed: 1
                        ) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
